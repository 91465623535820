import React, { useState, useEffect } from 'react';
import Layout, { FullWidthContainer } from '../../components/Layout';
import { graphql, navigate } from 'gatsby';
import Header from '../../components/Header';
import GeneratorBox from '../../components/GeneratorBox';
import Button from '../../components/Button';
import { generateIcon, infoIcon, piIcon } from '../../utilities/icons';
import AboutSectionWithCard from '../../components/AboutSectionWithCard';
import CategorySection from '../../components/CategorySection';
import DisclaimerSection from '../../components/DisclaimerSection';
import Footer from '../../components/Footer';
import Meta from '../../components/Meta';
import { randNumberRange } from '../../utilities/random';
import { Helmet } from 'react-helmet';

const RandomAttr = ({ pageContext, data }) => {
    const [randomWord, setRandomWord] = useState([]);
    const [randomAttributes, setRandomAttributes] = useState([]);
    const {
        content,
        description,
        highlightWord,
        metaImageLocal,
        highlightWordColor,
        minNumberOfRandomWords,
        percentageOfWholeWords,
        wholeWords,
        randomWords,
        icon,
        attributes,
        spacesBetweenWords,
        title,
        type
    } = data.mongodbGeneratorninjaRandomattrs;
    const { category } = pageContext;

    let pages = [];
    let randomPages = [];
    data.allMongodbGeneratorninjaFixedattrs.nodes.forEach((node) => {
      if (node.category === category) {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
      }
      randomPages.push(node.slug);
    });
    data.allMongodbGeneratorninjaRandomattrs.nodes.forEach((node) => {
      if (node.category === category) {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
      }
      randomPages.push(node.slug);
    });
    data.allMongodbGeneratorninjaNocards.nodes.forEach((node) => {
      if (node.category === category) {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
      }
      randomPages.push(node.slug);
    });

    const generate = () => {
      generateAttr();
      if (randNumberRange(1,101) <= percentageOfWholeWords) return setRandomWord(wholeWords[randNumberRange(0, wholeWords.length)].split(' '));
      const randomWordsLength = randomWords.length + 1;
      const randomWordLength = randomWords.length === minNumberOfRandomWords ? minNumberOfRandomWords : randNumberRange(minNumberOfRandomWords, randomWordsLength);
      let newRandomWord = [];
      for (let i = 0; i < randomWordLength; i++) {
        newRandomWord.push(randomWords[i][randNumberRange(0, randomWords[i].length)])
      }
      setRandomWord(newRandomWord);
    }

    const generateTable = (num: number) => {
      let words = [];
      for (let i = 0; i < num; i++) {
        if (randNumberRange(1,101) <= percentageOfWholeWords) {
          words.push(wholeWords[randNumberRange(0, wholeWords.length)]);
          continue;
        }
        const randomWordsLength = randomWords.length + 1;
        const randomWordLength = randomWords.length === minNumberOfRandomWords ? minNumberOfRandomWords : randNumberRange(minNumberOfRandomWords, randomWordsLength);
        let newRandomWord = [];
        for (let i = 0; i < randomWordLength; i++) {
          newRandomWord.push(randomWords[i][randNumberRange(0, randomWords[i].length)])
        }
        words.push(newRandomWord.join(spacesBetweenWords ? ' ' : ''));
      }
      console.table(words);
    }

    const generateAttr = () => {
      const randomAttrLength = attributes.length;
      let newRandomAttributes = [];
      for (let i = 0; i < randomAttrLength; i++) {
        newRandomAttributes.push({name: attributes[i].name, value: attributes[i].values[randNumberRange(0, attributes[i].values.length)]})
      }
      setRandomAttributes(newRandomAttributes);
    }

    const randomUrl = () => {
      navigate(
        `${randomPages[randNumberRange(0, randomPages.length)]}`
      )
    }

    const highlightedWordStyle = { color: highlightWordColor };
    useEffect(() => generate(), []);

    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        generateTable(1000);
      }
    }, []);

    return (
        <Layout>
          <>
            <Meta title={title} description={description} path={pageContext.slug}  image={metaImageLocal.publicURL} />
            <FullWidthContainer>
              <>
                <Header type="h1" text={title} />
                <GeneratorBox>
                  <>
                    {randomWord.map((word, index) =>
                      <span key={`${word}_${index}`}>
                        <span style={highlightWord !== 0 && highlightWord === index + 1 ? highlightedWordStyle : {}}>{word}</span>
                        <span>{`${spacesBetweenWords ? ' ' : ''}`}</span>
                      </span>
                    )}
                  </>
                </GeneratorBox>
                <Button type="Function" light={true} text={`Generate Another ${type}`} handleClick={generate} icon={generateIcon} />
                <Button type="Function" light={false} text={`Go to a Random Generator`} handleClick={() => randomUrl()} icon={piIcon} />
              </>
            </FullWidthContainer>
            <AboutSectionWithCard
              more={pages}
              content={content}
              category={pageContext.category}
              icon={icon}
              type={type}
              name={spacesBetweenWords ? randomWord.join(' ') : randomWord.join('')}
              attributes={randomAttributes}
            />
            <CategorySection  />
            <DisclaimerSection />
            <Footer />
            </>
        </Layout>
    )
}

export default RandomAttr;

export const query = graphql`
query RandomAttrPagesTemplate($slug: String!) {
    mongodbGeneratorninjaRandomattrs(slug: {eq: $slug}) {
      content
      highlightWordColor
      highlightWord
      description
      icon
      metaImageLocal {
        publicURL
      }
      minNumberOfRandomWords
      percentageOfWholeWords
      randomWords
      wholeWords
      attributes {
        name
        values
      }
      spacesBetweenWords
      title
      type
    }
    allMongodbGeneratorninjaRandomattrs(
      filter: {active: {eq: true}, slug: {ne: $slug}}
    ) {
      nodes {
        title
        icon
        slug
        category
      }
    }
    allMongodbGeneratorninjaNocards(
      filter: {active: {eq: true}}
    ) {
      nodes {
        title
        icon
        slug
        category
      }
    }
    allMongodbGeneratorninjaFixedattrs(
      filter: {active: {eq: true}}
    ) {
      nodes {
        title
        icon
        slug
        category
      }
    }
  }
`;
